import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, AbstractControl, FormGroup, ValidatorFn, Validators, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { TestReportDetails, TestReportStatus } from '@models/report';
import {
  DeviceModelsGroupConfigs,
  DeviceModelsGroupModelConfig,
  DeviceModelGroupModelPatchConfirmState,
  DeviceModelsGroupModel
} from '@models/device-models';
import { Breadcrumbs } from '@models/breadcrumbs';
import { DropdownItem, LookupProvider } from '@models/dropdown';
import { RequestResult } from "@models/request";
import { DialogService } from '@services/dialog.service';
import { NotificationService } from '@services/notification.service';
import { DeviceModelsService } from '@services/device-models.service';
import { ReportService } from '@services/report.service';
import { InformationDialogComponent } from '@components/information-dialog/information-dialog.component';
import { ConfirmationDialogComponent } from '@components/confirmation-dialog/confirmation-dialog.component';
import { Utils } from '@services/utils';
import { finalize, forkJoin, Observable, of, Subject, takeUntil } from 'rxjs';
import { HttpParams } from '@constants/http-params';
import { FirmwareService } from "@services/firmware.service";
import { DeviceConfigsService } from '@services/device-configs.service';
import moment from 'moment';
import { LookupComponent } from '@components/lookup/lookup.component';
import { ConfirmDialogIcon } from "@models/dialogs";

export const MASK_SYMBOLS = {'0': {pattern: /[1-9]/, symbol: '0'}, '9': {pattern: /[0-9]/, symbol: '9'}, '8': {pattern: /[0-9]/, symbol: '8', optional: true}};
export const FIRMWARE_VERSION_MASK = '08.98888.98888';
export const EPP_CONFIG_VERSION_MASK = '08*';
export const IOS_ANDROID_VERSION_MASK = '08.98.98';
export const IOS_ANDROID_PATTERN = /^[1-9]\d?\.([1-9]\d?|0)\.([1-9]\d?|0)$/;
export const FILE_NAME_REGEXP = /^[A-Za-zА-Яа-я0-9~@#$%^\-_(){}'`.\s]+$/;

@Component({
  selector: 'app-test-report-edit-view',
  templateUrl: './test-report-edit-view.component.html',
  styleUrls: ['./test-report-edit-view.component.scss']
})
export class TestReportEditViewComponent implements OnInit, OnDestroy {

  public testReportId: number;
  public testReport: TestReportDetails;
  public loading = false;
  public breadcrumbs: Array<Breadcrumbs> = [{title: 'Test Reports', link: ['/reports', 'test-reports']}];
  public types: Array<DropdownItem> = [];
  public modelsGroups: Array<DropdownItem> = [];
  public wifiModuleVersions: Array<DropdownItem>;
  public eppJsonVersions: Array<DropdownItem>;
  public wifiModuleVersionsProvider: LookupProvider;
  public eppJsonVersionsProvider: LookupProvider;
  public iosVersionsProvider: LookupProvider;
  public androidVersionsProvider: LookupProvider;
  public modelsGroupProvider: LookupProvider;
  public modelsConfigs: Array<DeviceModelsGroupModelConfig>;
  public availableConfigs: Array<Array<DropdownItem>> = [];
  public selectedConfigs: Array<DropdownItem> = [];
  public isMainModel: Array<boolean>;
  public activeTab = 0;
  public modelsIncomplete = false;
  public form: FormGroup;
  public highlightErrors = false;
  public fileName: string;
  public invalidFileName = false;
  public file: File;
  public MASK_SYMBOLS = MASK_SYMBOLS;
  public FIRMWARE_VERSION_MASK = FIRMWARE_VERSION_MASK;
  public EPP_CONFIG_VERSION_MASK = EPP_CONFIG_VERSION_MASK;
  public IOS_ANDROID_VERSION_MASK = IOS_ANDROID_VERSION_MASK;
  public DeviceModelGroupModelPatchConfirmState = DeviceModelGroupModelPatchConfirmState;

  @ViewChild('information') information: TemplateRef<any>;
  @ViewChild('ios') iosLookup: LookupComponent;
  @ViewChild('android') androidLookup: LookupComponent;
  private destroyed = new Subject<void>();
  private readonly returnData: any;
  private versionValidators: Array<ValidatorFn> = [Validators.required, Validators.pattern(IOS_ANDROID_PATTERN)];

  constructor(private deviceModelsService: DeviceModelsService, private reportService: ReportService,
      private firmwareService: FirmwareService, private configService: DeviceConfigsService, private dialogService: DialogService,
      private notificationService: NotificationService, private utils: Utils, private formBuilder: FormBuilder, private router: Router) {
    this.returnData = this.router.getCurrentNavigation().extras.state;
  }

  public ngOnInit(): void {
    this.loading = true;
    this.testReportId = Number(this.utils.lookupParam(HttpParams.TEST_REPORT_ID));
    this.initForm();
    forkJoin({
      types: this.reportService.getTestReportTypes(),
      modelGroups: this.deviceModelsService.getAllDeviceModelsGroups(),
      wifiModulesVersions: this.firmwareService.getFirmwareVersions(),
      eppVersions: this.configService.getDeviceConfigsVersions(),
      iosVersions: this.reportService.getIosVersions(),
      androidVersions: this.reportService.getAndroidVersions(),
      testReport: this.testReportId ? this.reportService.getTestReportById(this.testReportId) : of(null),
    }).pipe(takeUntil(this.destroyed), finalize(() => this.loading = false))
        .subscribe(({types, modelGroups, wifiModulesVersions, eppVersions, iosVersions, androidVersions, testReport}) => {
      this.breadcrumbs.push({title: this.testReportId ? testReport.fileName : 'New Test Report'});
      this.types = types.map(x => ({value: x.code, title: x.name}));
      this.modelsGroups = modelGroups.map(x => ({value: x.id, title: x.name, original: x}));
      this.modelsGroupProvider = this.createProviderFromItems(this.modelsGroups);
      this.wifiModuleVersions = wifiModulesVersions.map(x => ({value: x.id, title: x.version}));
      this.eppJsonVersions = eppVersions.map(x => ({value: x, title: '' + x}));
      this.wifiModuleVersionsProvider = this.createProviderFromItems(this.wifiModuleVersions);
      this.eppJsonVersionsProvider = this.createProviderFromItems(this.eppJsonVersions);
      this.iosVersionsProvider = this.createProviderFromItems(iosVersions, true);
      this.androidVersionsProvider = this.createProviderFromItems(androidVersions, true);
      if (this.testReportId) {
        this.testReport = testReport;
        if (testReport.status === TestReportStatus.DELETED) {
          this.router.navigate(['/reports', 'test-report', '' + this.testReportId]);
        }
        this.fillForm();
        this.fileName = this.testReport.fileName;
      }
      this.adjustIosAndroidField(true);
      this.adjustIosAndroidField(false);
    }, (err: HttpErrorResponse) => {
      this.breadcrumbs.push({title: 'Test Report'});
      this.notificationService.error('Failed to load report');
    });
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public showInfo(): void {
    this.dialogService.showModal(InformationDialogComponent, { maxWidth: '800px', data: {
      title: 'How to work with Test Report Card',
      text: this.information
    }});
  }

  public setMasterModel(index: number, value: boolean): void {
    this.isMainModel.fill(false);
    this.isMainModel[index] = value;
    this.checkComplete();
  }

  public checkComplete(): void {
    this.modelsIncomplete = !!this.selectedConfigs.filter(x => !x).length || this.isMainModel.filter(x => !!x).length !== 1;
  }

  public adjustIosAndroidField(iosOrAndroid: boolean): void {
    const field = iosOrAndroid ? (this.form.controls as any).iosVersion as FormControl : (this.form.controls as any).androidVersion as FormControl;
    if (iosOrAndroid ? this.form.value.ios : this.form.value.android) {
      field.enable();
      field.addValidators(this.versionValidators);
    } else {
      field.removeValidators(this.versionValidators);
      field.setValue('');
      field.disable();
    }
    field.updateValueAndValidity();
    this.form.controls['ios'].updateValueAndValidity();
    this.form.controls['android'].updateValueAndValidity();
  }

  public resetErrors(): void {
    this.highlightErrors = this.invalidFileName = false;
  }

  public save(): void {
    if (this.form.invalid || !this.fileName || !this.testReportId && !this.file ||this.modelsIncomplete) {
      return;
    }
    if (!FILE_NAME_REGEXP.test(this.fileName) || this.fileName.split('.').length > 2 || this.fileName.startsWith(' ') || this.fileName.endsWith(' ')) {
      this.invalidFileName = true;
      return;
    }
    const form = this.form.value;
    const testReport = {
      id: this.testReportId,
      fileName: this.fileName,
      type: {code: form.type.value, name: form.type.title},
      modelGroup: {id: form.modelsGroup.value, name: form.modelsGroup.title},
      wifiModuleVersion: {id: form.wifiModuleVersion.value, version: form.wifiModuleVersion.title},
      eppVersion: form.eppVersion.value,
      ios: form.ios,
      iosVersion: form.iosVersion,
      android: form.android,
      androidVersion: form.androidVersion,
      status: this.testReport?.status,
      comment: form.comment,
      models: this.modelsConfigs.map((model, index) => {
        return {model: model.model, main: this.isMainModel[index], logicConfig: this.selectedConfigs[index].original}
      }),
      author: this.testReport?.author,
      createdAt: this.testReport?.createdAt,
    } as TestReportDetails;
    this.loading = true;
    const promise = this.testReportId ? this.reportService.updateTestReport(testReport)
      : this.reportService.createTestReport(this.file, testReport);
    promise.pipe(takeUntil(this.destroyed), finalize(() => this.loading = false))
        .subscribe((reportId: number) => {
      if (this.testReportId) {
        this.notificationService.success(`Test Report ${testReport.fileName || ''} successfully updated`);
        this.cancel();
      } else {
        this.reportService.activateTestReportProcedure(reportId, this.fileName).pipe(takeUntil(this.destroyed)).subscribe((activateResult: RequestResult) => {
          if (activateResult.success) {
            this.notificationService.success(`Test Report ${testReport.fileName || ''} successfully created and activated`);
          } else {
            this.notificationService.success(`Test Report ${testReport.fileName || ''} successfully created, but yet is not activated`);
          }
          this.cancel();
        }, () => {
          this.notificationService.success(`Test Report ${testReport.fileName || ''} successfully created, but yet is not activated`);
          this.cancel();
        });
      }
    }, (error: HttpErrorResponse) => {
      const actionMade = this.testReportId ? 'update' : 'create'
      this.notificationService.error(`Failed to ${actionMade} Test Report ${testReport.fileName || ''}`);
    });
  }

  public navigateTab(currentTab: number, tab: number): void {
    if (currentTab === 0 && tab === 1) {
      this.iosLookup?.blur(true);
      this.androidLookup?.blur(true);
      this.form.updateValueAndValidity();
      if (this.form.invalid) {
        this.highlightErrors = true;
        return;
      } else {
        this.setAvailableConfigs();
      }
    }
    if (currentTab === 1 && tab === 2 && !this.testReportId) {
      this.generateFileName();
    }
    this.activeTab = tab;
  }

  public cancel(): void {
    this.router.navigate(this.returnData ? ['/' + this.returnData.path] : ['/reports', 'test-reports']);
  }

  public confirmModelPatch(model: DeviceModelsGroupModel): void {
    this.dialogService.showModal(ConfirmationDialogComponent, { width: '550px', data: {
        title: 'Patch not confirmed',
        text: `Unconfirmed logical JSON modification was found: modification file was added, edited or archived for this logical JSON`,
        icon: ConfirmDialogIcon.WARNING,
        agreeButtonText: 'Confirm'
      }}).afterClosed().pipe(takeUntil(this.destroyed)).subscribe((result) => {
      if (result) {
        this.loading = true;
        this.reportService.confirmTestRepostModelPatch(model.id).pipe(takeUntil(this.destroyed), finalize(() => this.loading = false)).subscribe(() => {
          model.patchConfirmed = DeviceModelGroupModelPatchConfirmState.PATCH_CONFIRMED;
          this.notificationService.success('Model patch confirmed');
        }, (error: HttpErrorResponse) => {
          this.notificationService.error('Something went wrong. Please try again');
          console.error(error);
        });
      }
    });
  }

  private createProviderFromItems(items: Array<DropdownItem | string>, simpleStrings = false): LookupProvider {
    return {
      search: (query: string): Observable<Array<DropdownItem>> => {
        return of(items.filter(x => {
          const text = simpleStrings ? x as string : (x as DropdownItem).title;
          return (text || '').toLowerCase().includes(query.toLowerCase());
        }).map(x => {
          return (simpleStrings ? {value: x, title: x} : x) as DropdownItem;
        }));
      }
    };
  }

  private setAvailableConfigs(): void {
    if (this.form.value.modelsGroup?.value && !this.modelsConfigs) {
      this.deviceModelsService.getDeviceModelsGroupConfigs(this.form.value.modelsGroup.value).subscribe((modelsGroupConfigs: DeviceModelsGroupConfigs) => {
        this.modelsConfigs = modelsGroupConfigs?.modelsConfig || [];
        this.availableConfigs = this.modelsConfigs.map(x => (x.configs || []).map(y => ({value: y.id, title: y.name, original: y})));
        this.availableConfigs.forEach((configsList: Array<DropdownItem>) =>
          configsList.sort((a, b) => (a.original.latest ? 1 : 0) - (b.original.latest ? 1 : 0)));
        this.selectedConfigs = new Array(this.modelsConfigs.length).fill(null);
        this.isMainModel = new Array(this.modelsConfigs.length).fill(false);
        if (this.testReportId && this.testReport) {
          (this.testReport.models || []).forEach((modelConfig, index) => {
            const row = this.modelsConfigs.findIndex(x => x.model.id === modelConfig.model?.id);
            if (row >= 0) {
              this.selectedConfigs[row] = this.availableConfigs[row].find(x => x.value === modelConfig.logicConfig?.id);
              this.isMainModel[row] = modelConfig.main;
            }
          });
        } else {
          this.modelsConfigs.forEach((x, index) => {
            const latest = x.configs.find(x => x.latest);
            if (latest) {
              this.selectedConfigs[index] = this.availableConfigs[index].find(x => x.value === latest.id);
            }
          });
        }
        this.checkComplete();
      });
    }
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      type: [null, Validators.required],
      modelsGroup: [null, Validators.required],
      wifiModuleVersion: [null, Validators.required],
      eppVersion: [null, Validators.required],
      ios: [false, this.validatePlatformSelected()],
      iosVersion: '',
      android: [false, this.validatePlatformSelected()],
      androidVersion: '',
      comment: '',
    });
  }

  private fillForm(): void {
    const setItem = (items: Array<DropdownItem>, value: any) => items.find(x => x.value === value);
    this.form.patchValue({
      type: setItem(this.types, this.testReport.type?.code),
      modelsGroup: setItem(this.modelsGroups, this.testReport.modelGroup?.id),
      wifiModuleVersion: setItem(this.wifiModuleVersions, this.testReport.wifiModuleVersion?.id),
      eppVersion: setItem(this.eppJsonVersions, this.testReport.eppVersion),
      ios: this.testReport.ios,
      iosVersion: this.testReport.iosVersion,
      android: this.testReport.android,
      androidVersion: this.testReport.androidVersion,
      comment: this.testReport.comment,
    });
  }

  private generateFileName(): void {
    try {
      const form = this.form.value;
      const typeName = form.type.value.substring(0, 2).toUpperCase();
      const createDate = moment(this.testReportId ? this.testReport.createdAt : new Date()).format('DD-MM-YYYY');
      const deviceTypeName = form.modelsGroup.original.deviceType.name;
      const masterModelIndex = this.isMainModel.findIndex(x => x);
      const masterModel = this.modelsConfigs[masterModelIndex].model;
      const iosVersion = form.ios ? '_IOs_' + form.iosVersion.replace(/\./g, '-') : '';
      const androidVersion = form.android ? '_Android_' + form.androidVersion.replace(/\./g, '-') : '';
      const wifiVersion = 'wifi-' + form.wifiModuleVersion.title.replace(/\./g, '-');
      const fileNameGenerated = typeName + '_' + createDate + '_' + deviceTypeName + '_' + masterModel.name + iosVersion + androidVersion + '_' + wifiVersion;
      this.fileName = fileNameGenerated.replace(/[\/:*?»<>|.]/g, '_').substring(0, 255);
    } catch {
      // skip
    }
  }

  private validatePlatformSelected(): ValidatorFn {
    return (control: AbstractControl) : ValidationErrors | null => {
      const form = this?.form?.getRawValue();
      return form?.ios || form?.android ? null : {platformRequired: true};
    };
  }

}
