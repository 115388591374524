<link rel="stylesheet" href="test-report-edit-view.component.scss">
<script src="../../../common/components/file-field/file-field.component.ts"></script>
<div class="test-report-edit-view inner-view">
  <div class="view-header with-breadcrumbs">
    <app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
    <h1 class="align-container small-spacing">
      <span>{{testReport ? 'Edit' : 'New'}}&nbsp;Test Report</span>
      <button class="information" (click)="showInfo()"></button>
    </h1>
  </div>
  <div class="view-section">
    <div class="panel">
      <div class="master-layout" [class.enable-first]="activeTab === 0"
          [class.enable-second]="activeTab === 1" [class.enable-third]="activeTab === 2">
        <div class="form-container">
          <div class="form-contents">
            <perfect-scrollbar>
              <div class="main-info-form scroll-area" [formGroup]="form" (mousedown)="resetErrors()">
                <div class="form-row">
                  <div class="label">Type</div>
                  <div class="field">
                    <app-dropdown [items]="types" formControlName="type" placeholder="Select Type" required
                                  [invalid]="form.controls['type'].errors && highlightErrors">
                    </app-dropdown>
                  </div>
                </div>
                <div class="form-row validation" *ngIf="form.controls['type'].errors && highlightErrors">
                  <div></div>
                  <div class="message">Type is required</div>
                </div>
                <div class="form-row">
                  <div class="label">Models Group</div>
                  <div class="field">
                    <app-lookup [provider]="modelsGroupProvider" formControlName="modelsGroup" placeholder="Select Models Group"
                                [invalid]="form.controls['modelsGroup'].errors && highlightErrors" [allowSearchEmpty]="true" [clearable]="true">
                    </app-lookup>
                  </div>
                </div>
                <div class="form-row validation" *ngIf="form.controls['modelsGroup'].errors && highlightErrors">
                  <div></div>
                  <div class="message">Models group is required</div>
                </div>
                <div class="form-row">
                  <div class="label">Wi-Fi Module</div>
                  <div class="field">
                    <app-lookup [provider]="wifiModuleVersionsProvider" formControlName="wifiModuleVersion" placeholder="Select Firmware Version"
                                [textFieldMask]="FIRMWARE_VERSION_MASK" [textFieldPatterns]="MASK_SYMBOLS"
                                [invalid]="form.controls['wifiModuleVersion'].errors && highlightErrors" [clearable]="true">
                    </app-lookup>
                  </div>
                </div>
                <div class="form-row validation" *ngIf="form.controls['wifiModuleVersion'].errors && highlightErrors">
                  <div></div>
                  <div class="message">Wi-Fi Module Version is required</div>
                </div>
                <div class="form-row">
                  <div class="label">Epp JSON</div>
                  <div class="field">
                    <app-lookup [provider]="eppJsonVersionsProvider" formControlName="eppVersion" placeholder="Select Epp Config Version"
                                [textFieldMask]="EPP_CONFIG_VERSION_MASK" [textFieldPatterns]="MASK_SYMBOLS"
                                [invalid]="form.controls['eppVersion'].errors && highlightErrors" [clearable]="true">
                    </app-lookup>
                  </div>
                </div>
                <div class="form-row validation" *ngIf="form.controls['eppVersion'].errors && highlightErrors">
                  <div></div>
                  <div class="message">Epp config is required</div>
                </div>
                <div class="form-row">
                  <div class="label">iOS</div>
                  <div class="field">
                    <app-checkbox formControlName="ios" (ngModelChange)="adjustIosAndroidField(true)">
                    </app-checkbox>
                  </div>
                </div>
                <div class="form-row">
                  <div class="label">Mobile app version (iOS)</div>
                  <div class="field">
                    <app-lookup [provider]="iosVersionsProvider" formControlName="iosVersion" placeholder="Mobile App Version (iOS)" #ios
                                [textFieldMask]="IOS_ANDROID_VERSION_MASK" [textFieldPatterns]="MASK_SYMBOLS" [allowCustomInput]="true"
                                [invalid]="form.controls['iosVersion'].errors && highlightErrors" [clearable]="true">
                    </app-lookup>
                  </div>
                </div>
                <div class="form-row validation" *ngIf="(form.controls['iosVersion'].errors || form.controls['ios'].errors) && highlightErrors">
                  <div></div>
                  <div class="message" *ngIf="form.controls['ios']?.errors?.platformRequired">At least one of the platforms version is required</div>
                  <div class="message" *ngIf="form.controls['iosVersion']?.errors?.required">Mobile app version (iOS) is required</div>
                  <div class="message" *ngIf="form.controls['iosVersion']?.errors?.pattern">Invalid mobile app version (iOS)</div>
                </div>
                <div class="form-row">
                  <div class="label">Android</div>
                  <div class="field">
                    <app-checkbox formControlName="android" (ngModelChange)="adjustIosAndroidField(false)">
                    </app-checkbox>
                  </div>
                </div>
                <div class="form-row">
                  <div class="label">Mobile app version (Android)</div>
                  <div class="field">
                    <app-lookup [provider]="androidVersionsProvider" formControlName="androidVersion" placeholder="Mobile App Version (Android)" #android
                                [textFieldMask]="IOS_ANDROID_VERSION_MASK" [textFieldPatterns]="MASK_SYMBOLS" [allowCustomInput]="true"
                                [invalid]="form.controls['androidVersion'].errors && highlightErrors" [clearable]="true">
                    </app-lookup>
                  </div>
                </div>
                <div class="form-row validation" *ngIf="form.controls['androidVersion'].errors && highlightErrors">
                  <div></div>
                  <div class="message" *ngIf="form.controls['androidVersion'].errors.required">Mobile app version (Android) is required</div>
                  <div class="message" *ngIf="form.controls['androidVersion'].errors.pattern">Invalid mobile app version (Android)</div>
                </div>
                <div class="form-row">
                  <div class="label align-top">Comment</div>
                  <div class="field">
                    <textarea class="value-edit comments" formControlName="comment" maxlength="512">
                    </textarea>
                  </div>
                </div>
              </div>
            </perfect-scrollbar>
            <div class="action-buttons align-container space-between">
              <button class="secondary" (click)="cancel()">Cancel</button>
              <button class="primary" (click)="navigateTab(0, 1)">Next</button>
            </div>
          </div>
        </div>
        <div class="models-form form-container">
          <div class="form-contents">
            <perfect-scrollbar *ngIf="modelsConfigs && modelsConfigs.length">
              <table class="results-table models-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th class="wide-column">Logic Json</th>
                    <th class="narrow-column">Master</th>
                    <th class="warning-line"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let model of modelsConfigs; index as index">
                    <td><a class="button link-button" [routerLink]="['/administration', 'device-models', model.model.id]">{{model.model.name}}</a></td>
                    <td class="wide-column">
                      <app-dropdown [items]="availableConfigs[index]" [(ngModel)]="selectedConfigs[index]"
                        placeholder="Select Config" (ngModelChange)="checkComplete()"></app-dropdown>
                    </td>
                    <td class="centered narrow-column">
                      <div class="checkbox-container">
                        <app-checkbox [ngModel]="isMainModel[index]" (ngModelChange)="setMasterModel(index, $event)"></app-checkbox>
                      </div>
                    </td>
                    <td class="warning-line">
                      <div class="attention-icon-container" *ngIf="model.model?.patchConfirmed === DeviceModelGroupModelPatchConfirmState.PATCH_NOT_CONFIRMED"
                           triggers="mouseenter:mouseleave" container="body" [placement]="['top-right', 'bottom-right']" ngbPopover="Patch for this model is not confirmed">
                        <div class="button attention-bubble model-patch-confirmed" (click)="confirmModelPatch(model.model)" >
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </perfect-scrollbar>
            <app-no-data *ngIf="!modelsConfigs || !modelsConfigs.length"></app-no-data>
            <div class="spacer"></div>
            <div class="action-buttons align-container space-between">
              <div class="left-buttons">
                <button class="secondary" (click)="cancel()">Cancel</button>
              </div>
              <div class="right-buttons">
                <button class="secondary" (click)="navigateTab(1, 0)">Back</button>
                <button class="primary" [disabled]="modelsIncomplete" (click)="navigateTab(1, 2)">Next</button>
              </div>
            </div>
          </div>
        </div>
        <div class="form-container" (mousedown)="resetErrors()">
          <div class="form-contents">
            <div class="files-form">
              <div class="form-row" *ngIf="!testReportId">
                <div class="label">File</div>
                <div class="field">
                  <app-file-field placeholder="Select Report File" (changed)="file = $event" [clearable]="true"
                                  extensions="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/msword,
                      application/vnd.openxmlformats-officedocument.wordprocessingml.document"></app-file-field>
                </div>
              </div>
              <div class="form-row">
                <div class="label">File Name</div>
                <div class="field">
                  <input type="text" class="input-field" placeholder="Enter file name" [(ngModel)]="fileName" maxlength="255" [class.invalid]="invalidFileName">
                </div>
              </div>
              <div class="form-row validation" *ngIf="invalidFileName">
                <div></div>
                <div class="message">Invalid file name, special symbols are not allowed</div>
              </div>
            </div>
            <div class="spacer"></div>
            <div class="action-buttons align-container space-between">
              <div class="left-buttons">
                <button class="secondary" (click)="cancel()">Cancel</button>
              </div>
              <div class="right-buttons">
                <button class="secondary" (click)="navigateTab(2, 1)">Back</button>
                <button class="primary" [disabled]="!fileName || (!testReportId && !file)" (click)="save()">Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-loading [show]="loading">
  </ngx-loading>
</div>
<ng-template #information>
  <div><b>Test Report Card</b> – all information about the test reports, including the file, characteristics, their status and mappings to smart device models:</div>
  <ul class="less-space">
    <li><b>Type</b> – type of testing that was performed according to the test report (Regression/Functional)</li>
    <li><b>Models group</b> – name of the model group to which the test report belongs</li>
    <li><b>Master-model</b> – the name of the model on which the test was actually performed</li>
    <li><b>Epp (IC)</b> – an ID of a motherboard of a device, which this file is available for</li>
    <li><b>iOS version</b> – iOS app version used in testing</li>
    <li><b>Android version</b> – Android app version used in testing</li>
    <li><b>Status</b> – test report status (Active/ Inactive/ Deleted/ Draft)</li>
    <li><b>Author</b> – the name of the user who uploaded the test report</li>
    <li><b>Created</b> – date and time when the report was added to the admin console</li>
    <li><b>Editor</b> – the name of the user who last edited the test report</li>
    <li><b>Updated</b> – date and time of the last report editing in the admin console</li>
    <li><b>Comment</b> – comment on the test report
    <li><b>Models</b> – models in the group of models on which the testing was performed, information is presented for each model:
      <ul>
        <li><b>NAME</b> – device model name</li>
        <li><b>LOGIC JSON</b> – version of the JSON that was used in testing</li>
        <li><b>MASTER MODEL</b> – characteristic that determines on which model the test was actually performed. Only one model in the list can have this characteristic.</li>
      </ul>
    </li>
  </ul>
</ng-template>
